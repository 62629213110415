@import url(https://use.typekit.net/uqy0veg.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700);
body {
    font-family: 'Montserrat', sans-serif;
    margin: 0px;
    padding: 0px;
    background-color: #fff;
}

.mainContainer {
    background-color: #ffffff;
    overflow-x: hidden;


}

.panelWithImageBackground {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.headerPanel {
    background: url(/images/header-bg.png);
    min-height: 600px;    
    padding:30px;
    background-position-y:-150px;
}

.headerImg img{
    width: 75%;
}

.headerImg.left {
    text-align: left;
}

.headerImg.right {
    text-align: right;
}

.defaultPadding {
    padding:10px;
}

.paddingTop20 {
    padding-top: 20px;
}

.paddingBottom20 {
    padding-bottom: 20px;
}

.marginAbove {
    margin-top: 10px;
}

.blueStripCta {
    background-color: #45BEE2;
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
}

.purpleStripCta {
    background-color: #9e6bab;
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
}

.greenStripCta {
    background-color: #a0c36c;
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
}

.greyStripCta {
    background-color: #444444;
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
}


.blueText {
    color:#45BEE2;
}

.purpleText {
    color:#9e6bab;
}

.greenButton {
    background-color: #a0c36c;
    border-color:  #a0c36c;
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    padding:10px;
    cursor: pointer;
}

.blueButton {
    background-color: #45BEE2;
    border-color:  #45BEE2;
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    padding:10px;
    cursor: pointer;
}

.greenButton:hover, .greenButton:active, .greenButton:active:focus {
    background-color: #9e6bab;
    border-color:  #9e6bab;
}

.fullWidth {
    width: 100%;
}

.pavillionPanel {
    background: url(/images/pavillion.jpg);
    min-height: 800px;    
    padding:30px;
    cursor: pointer;
}

.ctaDome {
    position: absolute;
   bottom: 0 ;
   width:500px;
   left:50%;
   margin-left: -250px;
}

.ctaDomeSmall {
    position: absolute;
   bottom: 0 ;
   width:300px;
   left:50%;
   margin-left: -150px;
}


.ctaDome img, .ctaDomeSmall img {
    width:100%;
    
}



.ctaCircle {
    border-radius: 50%;
    width: 210px;
    height: 210px;
    padding:20px;
    text-align: center;
    font-weight: 400;
}

.ctaCircleSmall {
    border-radius: 50%;
    width: 125px;
    height: 125px;
    padding:20px;
    text-align: center;
    font-weight: 400;
}

.greenBg {
    background-color: #a0c36c;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
}

.purpleBg {
    background-color: #9e6bab;
    color: #ffffff;
    font-size: 14px;    
    font-weight: 700;
}

.blueBg {
    background-color: #45BEE2;
    color: #ffffff;
    font-size: 14px;    
    font-weight: 700;
}


.zoomIcon {
    color: rgb(255,255,255,0.7);
    text-align: right;
    cursor: pointer;
    width: 100%;
}


.hawaiiSunset {
    background: url(/images/sunset.jpg);
    min-height: 200px;  
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;   
}

.hawaiiHonolulu {
    background: url(/images/honolulu.jpg);
    min-height: 200px;  
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top: solid #ffffff 5px
}

.hawaiiSnorkelling {
    background: url(/images/snorkelling.jpg);
    min-height: 400px;   
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-left: solid #ffffff 5px;
    padding:20px;
}

.hawaiiSnorkelling img {
    position: absolute;
    bottom: 0;
    width: 300px;
    left: -151px;
}

.earlybirdCar {
    background: url(/images/earlybird-car.jpg);
    min-height: 800px;   
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding:20px;
}

.porscheLogo {
    position: absolute;
    left:20px;
    bottom:20px;
}

.earlybirdCar .cta {
    position: absolute;
    bottom: 0;
    width: 300px;
    right: 100px;
}

.luxuryCars {
    padding:0;
}

.luxuryCarChoice1 {
    border-right: 5px solid #ffffff;
    padding:0;
}

.luxuryCarChoice2 {
    border-left: 5px solid #ffffff;
    padding:0;
}

.luxuryCar {
    min-height: 400px;   
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding:20px;
    color: #ffffff;    
    font-size: 18px;
    
    
}

.textShadow {
    text-shadow: 1px 1px rgba(0,0,0,0.4)
}

.luxuryCarOrCircle {
    width:100px;
    height:100px;
    font-size: 36px;
    font-weight: 700;
    left:50%;
    top:-50px;
    position: absolute;
    margin-left: -50px;
}

.luxuryCar.car1 {
    border-bottom: 5px solid #ffffff;
    background-image: url(/images/luxury-car1.jpg);
}

.luxuryCar .choice1Title  {
    position: absolute;
    bottom:0;
    left:0;
    padding-left: 30px;
}

.luxuryCar .choice2Title  {
    position: absolute;
    bottom:0;
    right:0;
    padding-right: 30px;
}


.luxuryCar.car2 {
    border-top: 5px solid #ffffff;
    background-image: url(/images/luxury-car2.jpg);
}

.luxuryCar.car3 {
    border-bottom: 5px solid #ffffff;
    background-image: url(/images/luxury-car3.jpg);
}

.luxuryCar.car4 {
    border-top: 5px solid #ffffff;
    background-image: url(/images/luxury-car4.jpg);
}

.luxuryCar .cta {
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    padding-top:30px;
}

.audiLogo {
    position: absolute;
    bottom:50px;
    left:30px;
    width: 52px;    
}

.fordLogo {
    position: absolute;
    bottom:50px;
    right: 30px;
    width: 178px;
}

.textBottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.holidays {
    padding:0;
}

.holiday {
    min-height: 250px;   
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    padding:20px;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    padding:10px 0;
}

.holidayTitle {
    text-align: center;
    text-transform: uppercase;
    color:#444444;
    font-weight: 500;
    font-size:14px;
}

.holidayOrCircle {
    width:80px;
    height:80px;
    font-size: 24px;
    font-weight: 700;
    
    margin-left: -45px;
    
    margin-top: -55px;
    position: absolute;
    
}

.holiday1 {
    background-image: url(/images/holiday1.jpg);
    border-right:5px solid #ffffff;
}

.holiday2 {
    background-image: url(/images/holiday2.jpg);
    border-left:5px solid #ffffff;
    border-right:5px solid #ffffff;
}

.holiday3 {
    background-image: url(/images/holiday3.jpg);
    border-right:5px solid #ffffff;
}

.holiday4 {
    background-image: url(/images/holiday4.jpg);
    border-left:5px solid #ffffff;
    border-right:5px solid #ffffff;
}

.holiday5 {
    background-image: url(/images/holiday5.jpg);
    border-right:5px solid #ffffff;
    border-left:5px solid #ffffff;
}

.holiday6 {
    background-image: url(/images/holiday6.jpg);
    border-left:5px solid #ffffff;
}

.holiday7 {
    background-image: url(/images/holiday7.jpg);
    border-right:5px solid #ffffff;
    border-left:5px solid #ffffff;
}

.holiday8 {
    background-image: url(/images/holiday8.jpg);
    border-left:5px solid #ffffff;
}

.noPadding {
    padding: 0;
}

.noMargin {
    margin: 0;
}

.proceedsTxt{
    padding:40px;
    font-size: 12px;
}

.proceedsImg{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/images/proceeds.jpg);
    min-height: 250px;
}

.footer{
    display: block;
    /*height: 100px;*/
    clear: both;
    background-color: #444;
    padding-top: 2px;
    padding-bottom: 4px;
}

.footer img {
    display: block;
    margin:5px auto;
}

.footer p {
    text-align: center;
    font-size: 12px;
    color: #fff;
}

.footer a:link {
    color:#ffffff;
}

.prizeCarousel {
    width: 100%;
    padding:0;
}

.galleryCarousel img {
    width:100%;
}


.carouselPrizeItem {
    text-align: center;
}


.heroCarousel .carousel-indicators{
    top:20px;
    height:50px;

}

.heroCarousel .carousel-item{
    cursor: pointer;
}

.heroCarousel img {
    width:100%;
}

.buyCarousel .carousel-indicators{
    bottom:20px;
    height:50px;

}

.buyCarousel .carousel-item{
    cursor: pointer;
}

.buyCarousel img {
    width:100%;
}

.heroOverlay {
    position: absolute;
}

.heroCtaCircle {
    position: absolute;
    left:20px;
    top:20px;
}

.buyCarouselLogo {
    position: absolute;
    right:40px;
    top:30px;
}


.close {
    font-size: 2.5rem;
}

.gallery .close {
    font-size: 2.5rem;
    color: #ffffff;
}



.gallery .modal-header {
    background: transparent;
    border-bottom:none;
}

.gallery .modal-content {
    background: transparent;
    border: none;
}

.sticky {
    height: 100px;
    background: #45BEE2;
    position: fixed;
    z-index: 999;
}

.sticky.on {
    top:0;
    transition: top 0.5s ease-out;
}

.sticky.off {
    transition: top 0.5s ease-out;
    top: -250px
}

.stickyBtn {
    margin-top: 20px;
}

.stickyLogo {
    max-height: 200px;
    position: absolute;
    left:50%;
    margin-left:-100px;
}

.menuBtn {
    color:#ffffff;
    margin-top: 20px;
    cursor: pointer;    
}

.menuDropdown button{
    background: transparent !important;
    border:none;
}

.menuDropdown .dropdown-toggle::before {
    display: none;
}

.menuDropdown .dropdown-item a {
    color:#000000;
}

.select-tickets {
    padding:30px 10px 30px 10px;
}

.number-of-tickets-btn {
    background-color: #a0c36c;
    border: none;
    
}

.number-of-tickets {
    display: inline-block;
    padding:10px;
    font-size: 24px;
}

.light-green-bg {
    background-color: rgba(160,185,108, 0.15)
}

.light-purple-bg {
    background-color: rgba(158,107,171, 0.15)
}

.light-blue-bg {
    background-color: rgba(69,190,226, 0.15)
}

.vipInfo {
    font-style: italic;
    font-size: 14px;
}

.errorInput {
    color: #ff0000;
    border-color: #ff0000;
}

@media only screen and (max-width: 1024px) {
    .headerImg.right, .headerImg.left {
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .pavillionPanel {
        min-height: 450px;
    }


    .zoomIcon {
        text-align: center;
        margin-top: 25px;
    }

    .ctaCircle {
        margin:auto;
    }

    .hawaiiSnorkelling {
        border-left: none;
        border-top: solid #ffffff 5px
    }

    .hawaiiSnorkelling img {
        width: 300px;
        left: 50% !important;
        margin-left:-150px !important;
    }

    .porscheLogo {
        position: absolute;
        bottom:150px;
        width: 102px;
        left: 50% !important;
        margin-left:-51px !important;
    }
    
    .earlybirdCar .cta {
        width: 300px;
        left: 50% !important;
        margin-left:-150px !important;
    }

    .luxuryCarChoice1 {
        border-right: none;
        border-bottom: 5px solid #ffffff;
        padding:0;
    }
    
    .luxuryCarChoice2 {
        border-left: none;
        border-top: 5px solid #ffffff;
        padding:0;
    }

    .holiday3 {
        border-bottom:5px solid #ffffff;
    }
    
    .holiday4 {
        background-image: url(/images/holiday4.jpg);
        border-bottom:5px solid #ffffff;
    }
    
    .holiday5 {
        background-image: url(/images/holiday5.jpg);
        border-top:5px solid #ffffff;
    }
    
    .holiday6 {
        background-image: url(/images/holiday6.jpg);
        border-top:5px solid #ffffff;
    }

    .stickyBtn {
        font-size: 16px;
        margin-top: 12px;
    }

    
}





@media only screen and (max-width: 600px) {
    .headerImg img{
        width: 90%;
    }

    .panelWithImageBackground {
        background-size: cover;
    }

    .headerPanel {
        min-height: auto;
    }

    
  }


  .react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .react-autosuggest__input:disabled {
    background-color: #e9ecef;
    opacity: 1;
    }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 37px;
    width: 350px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  .errorInput {
    color: #ff0000;
    border-color: #ff0000;
}

/*
.printedTicket img {
    width: 400px;
    margin:0 auto;
}*/

.printedTicket .tickets {
    /* padding:40px; */
    /* border:4px solid #9e6bab; */
    /* margin-bottom: 50px; */
    /* width: 80%; */
}

.header {
    position: relative;
    padding-top: 40px;
    padding-bottom: 20px;
}

.header .title {
    color: #ff0000;
    font-size: 70px;
    line-height: 65px;
    font-weight: bold;
    text-align: right;
}

.header img {
    width: 600px;
    position: absolute;
    z-index: 100;
    bottom: -270px;
    margin-left: 30px;
}

.range-rover {
    position: relative;
    padding: 0;
    margin: 0;
}

.range-rover .bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.range-rover .fg {
    position: absolute;
    bottom: 150px;
    right: 50px;
    width: 60%;
}

.details {
    background-color: #9D6BAC;
    padding-bottom: 50px;
    position: relative;
    padding-left: 250px;
    padding-right: 100px;
    height:300px;
    margin-top: -100px;
    
}

.details img {
    position: absolute;
    width: 630px;
    z-index: 100;
    top: -50px;
    left: 200px;
}

/* .details .label-container {
    position: absolute;
    font-size: 22px;
    font-weight: 500;
    left: 300px;
    top: -0px;
    z-index: 110;
} */

.details .label-container {
    /* background-color: white; */
    padding-left:20px;
    padding-right:20px;
    padding-top:20px;
    font-size: 22px;
    font-weight: 500;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;
    z-index: 110;
    position: absolute;
}

.details .ticker-number {
    margin-top: 10px;
    font-size: 16px;
    width: 90%;
}

.thanks {
    background-color: #9D6BAC;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 30px;
    padding-bottom: 95px;
}

.thanks .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.thanks .text {
    font-size: 18px;
}

.footer {
    background-color: #45BEE2;
    text-align: center;
    font-size: 70px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    padding: 0;
}

.page-divider {
    height: 30px;
}


@page {
    size: A4;
    marks:none;
    margin: 0;
    margin-right:-10px;
        
  }
  @media print {
  
    html, body {
      width: 210mm;
      height:310mm;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    } 
  }

  .loaderContainer {
      width: 100%;
      text-align: center;
      margin-top: 100px;
  }
  
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #45BEE2;
    border-color: #45BEE2 transparent #45BEE2 transparent;
    -webkit-animation: lds-dual-ring 1.2s linear infinite;
            animation: lds-dual-ring 1.2s linear infinite;
  }
  @-webkit-keyframes lds-dual-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-dual-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
 

  .skewContainer {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-top: -50px;
  }
  
  .first,
.last {
  text-align: center;
  line-height: 40px;
  height: 40px;
  background: white;
  position: relative;
  display: inline-block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

}

.last {
    margin-top: 50px;
}
.first:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  height: 50%;
  width: 100%;
  -webkit-transform: SkewY(-2deg);
          transform: SkewY(-2deg);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  background: inherit;
}
.last:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 50%;
  width: 100%;
  -webkit-transform: SkewY(2deg);
          transform: SkewY(2deg);
  -webkit-transform-origin: top right;
          transform-origin: top right;
  background: inherit;
}

.terms-text {
    font-size: 11px;
    line-height: 12px;
}


.p-last {
    margin-bottom: 0px;
}
